import React from "react"

import MainContainer from "../components/containers/MainContainer"
import HeadData from "../data/HeadData"
import OneColumnPageLayout from "../components/layouts/OneColumnPageLayout"

const paragraph = 'font-body text-lg text-gray-900 mb-4'
const listItem = 'font-body text-lg text-gray-800'

const About = () => (
  <MainContainer>
    <HeadData
      title='About'
      description='The Purpose of the Orange County Sigma Chi Alumni Chapter.'
    />

    <OneColumnPageLayout>
      <h2 className='font-display text-4xl text-blue-900 mb-8 leading-tight'>The Purpose of the Orange County Sigma Chi Alumni Chapter</h2>

      <p className={paragraph}>The Orange County Alumni Chapter of the Sigma Chi Fraternity exists to:</p>

      <ul className='list-disc ml-8 sm:ml-4 leading-loose'>
        <li className={listItem}>To cultivate and maintain the high ideals of friendship, justice and learning.</li>
        <li className={listItem}>To strengthen the name and ideals of the Sigma Chi Fraternity.</li>
        <li className={listItem}>To provide and maintain a vehicle for all area Sigma Chi alumni to share in the bonds of brotherhood.</li>
        <li className={listItem}>To assist the Orange County undergraduate chapters.</li>
        <li className={listItem}>To support both financially and through participation, the programs of the Sigma Chi Fraternity and Foundation.</li>
        <li className={listItem}>To provide a career and employment guidance for both alumni and undergraduates.</li>
        <li className={listItem}>To provide a representative voice of area alumni regarding Fraternity issues.</li>
        <li className={listItem}>To contribute to the betterment of society and our communities by fostering healthy community relations and undertaking meaningful community service activities.</li>
      </ul>
    </OneColumnPageLayout>
  </MainContainer>
)

export default About
